import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'

import Back from '../../components/caseStudies/back'
import BrandBox from '../../components/caseStudies/brandBox'
import ContentWrapper from '../../components/share/contentWrapper'
import Details from '../../components/caseStudies/details'
import Header from '../../components/caseStudies/header'
import IntroRow from '../../components/share/introRow'
import Layout from '../../components/layout'
import Quote from '../../components/share/quote'
import SpeakToTeam from '../../components/share/speakToTeam'
import TypeFormButton from '../../components/share/typeFormButton'

import brandIcon from '../../images/brandLogos/harris.svg'

const query = graphql`
  query {
    header: file(relativePath: { eq: "caseStudies/header-harris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentImage: file(relativePath: { eq: "brandAgentIcon/phil_harris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Harris = props => {
  return (
    <Layout>
      <StaticQuery
        query={query}
        render={data => (
          <Fragment>
            <Header
              background={data.header.childImageSharp.fluid}
              year="2010"
              contentStyle={{ color: '#404041' }}
              iconStyle={{ width: '80px', filter: 'brightness(0%)' }}
              url="www.harrisre.com.au"
              linkStyle={{ color: '#404041' }}
              brandName="Harris Real Estate"
              icon={brandIcon}
              overlayColor="linear-gradient(122.45deg, rgba(255, 255, 255, 0.7) 45.4%, rgba(255, 159, 251, 0.7) 132.37%)"
              content={
                <Fragment>
                  From 5 staff in 2010, to 150 staff and 5 offices today - their
                  success continues. An award winning industry leader in
                  residential sales and property management with strong values.
                </Fragment>
              }
            />

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Details
                sizes={['Boutique', 'Largest in SA']}
                integrations={[
                  'CRMs',
                  'Pinters',
                  'Newspapers',
                  'Xero',
                  'Zendesk',
                ]}
                customTitle="# OF SALES IN 2018"
                customData={['1,700+']}
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc={`“Realhub’s speed of communication and ability to quickly make changes has been one of their greatest strengths.”`}
              />
              <IntroRow
                descWidth="85%"
                title="South Australia’s leading Real Estate brand knew it was time for a change of scenery."
                content={
                  <Fragment>
                    <Paragraph>
                      Achieving a dominant market position isn’t enough for some
                      people. Smart business owners know that if you let your
                      size and success go to your head and rest in your position
                      then it’s only a matter of time until another office comes
                      and takes the crown.
                    </Paragraph>
                    <Paragraph>
                      Phil and the team at Harris real estate quickly recognised
                      that there was technology available in the market that
                      would help them dramatically outperform the competition,
                      and outperform their previous capabilities with their
                      existing marketing software.
                    </Paragraph>
                    <Paragraph>
                      Taking advantage of a lull in the market before spring,
                      and knowing spring could be their biggest yet they knew
                      moving over, and doing so quickly would be the only way to
                      ensure they could continue to lead the market.
                    </Paragraph>
                    <Paragraph>
                      So the move to Realhub was decided upon. And within the
                      space of a few months, and multiple custom solutions to
                      their unique workflows the entire group of offices was
                      transferred over to a new way of working.
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                desc={`“Their passion for technology is evident as their system is always evolving and adapting to market changes.”`}
                descStyle={{ width: '100%' }}
                agentImage={data.agentImage.childImageSharp.fluid}
                agentName="Phil Harris"
                title="Founder & Director"
                agencyName="Harris"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <IntroRow
                descWidth="75%"
                content={
                  <Fragment>
                    <Paragraph>
                      Met with resounding support from the marketing and sales
                      teams, and after some very tricky migration specific
                      development even the accounts team were all ready to go.
                    </Paragraph>
                    <Paragraph>
                      Change, and learning a new way of operating is usually met
                      with a fair amount of resistance, understandably some
                      people are of the opinion of ‘if it ain’t broke, don’t fix
                      it’ but sometimes an alternative comes along that is so
                      enticing there’s no option but to take advantage and scale
                      your business further without committing additional
                      resources.
                    </Paragraph>

                    <Paragraph>
                      Scale your business and maintain a leading position.
                      <TypeFormButton
                        text="Join the team at Realhub today."
                        plain
                      />
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc="If we have ideas for system improvements we know that Realhub’s team will be just as excited as us to build and implement new features."
              />
            </ContentWrapper>

            <ContentWrapper>
              <SpeakToTeam
                uid="WpgxlK"
                avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
              />
            </ContentWrapper>
            <ContentWrapper style={{ margin: '40px auto' }}>
              <Back />
              <Flex>
                <BrandBox brandName="Cunninghams" />
                <BrandBox
                  brandName="BelleProperty"
                  iconStyle={{ height: '50px' }}
                />

                <BrandBox brandName="TheAgency" />
              </Flex>
            </ContentWrapper>
          </Fragment>
        )}
      />
    </Layout>
  )
}

export default Harris

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
